/* General styles */
body {
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height:1.4em;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* App container to use flexbox layout */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Main content area to take up available space */
.main-content {
  flex: 1;
}

/* Footer styles */
footer {
  background: #f9f9f9;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  padding: 1rem;
  flex-shrink: 0;
}

.footer {
  max-width: 1140px;
  margin: 0 auto;
}

.footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer ul li {
  margin-right: 20px;
}

.footer ul li:last-child {
  margin-right: 0;
}

.footer a {
  text-decoration: none;
  color: inherit;
}

.footer a:hover {
  opacity: 0.8;
}

/* Responsive styles */
@media only screen and (max-width: 600px) {
  .footer ul {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer ul li {
    margin-bottom: 10px;
  }

  .footer ul li:last-child {
    margin-bottom: 0;
  }
}

/* Other existing styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #cf5a16;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url("https://use.typekit.net/val2vrk.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");

.wrapper {
  max-width: 1140px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  flex: 1 0 auto;
}
a {color:#222222;
text-decoration: none;}
.brand {
  font-weight: bold;
  font-size: 22px;
}
.brand span {
  display: inline-block;
  min-width: 100px;
}

.site-header {
  position: relative;
  background-color: #fff;
}

.site-header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.nav__wrapper {
  display: flex;
}

.nav__item a {
  display: block;
  padding: 1.5rem 1rem;
  font-weight: 700;
}

.hero {
  margin-top: 100px;
}
.profile-pic img {
  display: block;
  margin: 0px auto;
  width:300px;
  height:auto;
}
.face-image {
  position: relative;
  display: block;
  width: 250px;
  height: 250px;
  margin: 0 auto;
}
.face-image img {
  z-index: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
.face-image img.active {
  z-index: 1;
}
h1 {
  font-size: 60px;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 20px;
}
h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 10px;
  text-align:left;
}
.subhead {
  font-size: 22px;
  line-height: 140%;
  margin-bottom: 20px;
}
.emoji {
  font-size: 19px;
}
.row ul {
  list-style: circle;
  line-height: 28px;
  padding-left: 20px;
}
.row ul li {
  padding-left: 5px;
}
.row ul li li {
  margin-left: 15px;
}
.section {
  margin-top: 100px;
}

.case {
  display: block;
  margin-bottom: 50px;
  padding: 25px;
  color: #fff;
}
.case p {
  line-height: 26px;
}

.strategy {
  background-color: #006251;
}
.innovation {
  background-color: #91c46d;
}
.ux {
  background-color: #eeeae0;
  color: #000;
}

.readmore {
  background: #000;
  color: #fff;
  padding: 15px;
  font-weight: 700;
  display: inline-block;
  margin-top: 60px;
}

.readmore:hover {
  color: #000;
  background-color: #fff;
}

.button {
  background: #fff;
  border:1px solid #222222;
  color: #222222;
  padding: 15px 25px;
  font-weight: 700;
  display: inline-block;
  border-radius:50px;
}
.button:hover {
  color: #fff;
  background-color: #222222;
}
.primary{background:#222222; color:#ffffff; margin-right:10px}
.primary:hover{background: #ffffff; color:#222222}
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.brands{
  display: flex;
  justify-content: space-between;
  margin: 50px auto 20px;
  text-align: left;
}
.services {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  text-align: left;
}

.services h2 {
  font-size: 32px;
  font-weight: 700;
}

.subhead2 {
  font-size: 18px;
  display: block;
  text-align: left;
  opacity:0.6;
  margin-bottom: 20px;
}
.brandshead {
  font-size: 18px;
  display: block;
  text-align: center;
  opacity:0.6;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.block {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.block .service-icon {
  width: 50px;
  margin-bottom: 20px;
}

.block h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}

.block p {
  font-size: 16px;
  margin-bottom: 15px;
}

.block hr {
  margin: 20px 0;
  border: none;
  border-top: 1px solid #ddd;
}

.block ul {
  list-style: none;
  padding-left: 0;
}

.block ul li {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 10px;
}

/* Responsive styles */
@media only screen and (max-width: 600px) {
  .services {
    flex-direction: column;
    margin-top: 40px;
  }

  .block {
    margin-bottom: 20px;
    padding: 20px;
  }

  .block h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .block p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .block ul li {
    font-size: 12px;
    margin-bottom: 5px;
  }
}


/* Responsive styles */
@media only screen and (max-width: 600px) {
  .services {
    margin-top: 40px;
  }

  .block {
    margin-bottom: 20px;
    padding: 20px;
  }

  .block h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .block p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .block ul li {
    font-size: 12px;
    margin-bottom: 5px;
  }
}


.about .button {
  display: block;
  text-align: center;
  margin: 0 auto;
  margin-top: 15px;
}

p.text {
  line-height: 26px;
  text-align: justify;
  margin-bottom: 10px;
}
.link{text-decoration:underline;}
.subheading {
  font-size: 18px;
  font-weight: 700;
  margin: 15px 0;
}
.sidenote {
  text-align: center;
  padding: 15px 0 5px 0;
}
.extra li a:hover {
  opacity: 0.6;
  padding-bottom: 10px;
}
.marginbottom {
  margin-bottom: 25px;
}
.footer {
  flex-shrink: 0;
  max-width: 1140px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0 auto;
  text-align: left;
  line-height: 24px;
  vertical-align: center;
}
.footer a:hover {
  opacity: 0.8;
}
.footer ul li {
  display: inline-block;
  margin-right: 20px;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 36px;
    line-height: 48px;
  }
  h2 {
    font-size: 22px;
    line-height: 32px;
  }
  .subhead{
    font-size:18px;
  }
  .hero {
    margin-top: 40px;
  }
  .case {
    padding: 20px;
    margin-bottom: 20px;
  }
  .readmore {
    margin-top: 20px;
    width: 50%;
    text-align: center;
  }
  .button {
    width: 100%;
    text-align: center;
        padding: 15px 0px;
  }
  .section {
    margin-top: 40px;
  }
  footer {
    line-height: 40px;
    margin-top: 40px;
  }
  .profile-pic img {
    margin: 0 auto;
  }
}

@keyframes fadeInUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.fadeInUp-animation {
  animation: 0.5s fadeInUp;
}